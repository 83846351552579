import { useMediaQuery } from 'react-responsive'

export default function useDeviceScreen() {
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })
  // const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  // const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

  // console.log({
  //   isDesktopOrLaptop, isBigScreen, isTabletOrMobile, isPortrait, isRetina,
  // })

  let screen = 'mobile'

  if (isDesktopOrLaptop) {
    screen = 'desktop'
  }

  if (isTabletOrMobile) {
    screen = 'mobile'
    if (isPortrait) {
      screen += '-portrait'
    }
    else {
      screen += '-landscape'
    }
  }

  return screen
}