import React from 'react'
import * as R from 'ramda'
import * as d3 from 'd3'

import { updateView, initView } from '@petlack/react-d3/view'

import '@petlack/react-d3/D3.css'

export default class D3 extends React.Component {
  constructor(props) {
    super(props)

    this.clearView = null
    this.update = null
    this._svgRef = null
  }
  componentDidMount() {
    console.log('D3', 'componentDidMount', this._svgRef)
    if (this._svgRef) {
      const { App, ...props } = this.props
      this.clearView = initView(d3.select(this._svgRef), App, props)
      updateView(d3.select(this._svgRef), App, props)
    }
  }
  componentWillUnmount() {
    if (this.clearView) {
      this.clearView()
    }
  }
  componentDidUpdate({ App: _, ...prevProps }) {
    const { App, ...props } = this.props

    console.log('[D3]', 'componentDidUpdate', { props, prevProps })
    if (!R.equals(props, prevProps)) {
      if (this._svgRef) {
        console.log('[D3] updating view')
        updateView(d3.select(this._svgRef), App, props)
      }
    }
  }
  render() {
    return (
      <div style={{ flex: 1 }}>
      <div className="full-screen" ref={ref => this._svgRef = ref}></div>
    </div>
    )
  }
}



// import React from 'react'
// import { useEffect, useRef } from 'react'
// import { select } from 'd3'

// import '@petlack/react-d3/D3.css'

// const noop = () => {}
// const log = noop
// // const log = console.log.bind(console, '[D3]')

// export function updateView(selector, App, props) {
//   var app = App(props)
//   return selector.call(app)
// }

// export default function D3({ App, ...props }) {
//   log('create')
//   const svgRef = useRef()
//   useEffect(() => {
//     log('App.create')
//     const parent = select(svgRef.current)
//     App.create(parent)
//   }, [App, svgRef])
//   useEffect(() => {
//     log('parent.call')
//     const parent = select(svgRef.current)
//     const app = App(props)
//     parent.call(app)
//   }, [App, props, svgRef])

//   return (
//     <div style={{ flex: 1 }}>
//       <div className="full-screen" ref={svgRef}></div>
//     </div>
//   )
// }