import React from 'react'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  Link,
} from 'react-router-dom'

import useDeviceScreen from './useDeviceScreen'

import Backgammon from '@petlack/backgammon-react/app'
import D3 from './D3'
import Visualisation from './Visualisation'

import Menu from './Menu'
// import Parallax from './Parallax'

import './App.css'

import useWindowSize from './useSize'

function Back() {
  return (
    <div className="Mobile-Back"><Link to="/">BACK</Link></div>
  )
}

function NLP() {
  return (
    <div className="NLP">
      <img src="/text.png" className="full-img" alt="Natural Language Processing" />
      <h2><span>Visualisation of news articles between Feb 2020 and July 2021</span></h2>
      <p>Above is a visualisation of 350,000 news articles collected in time period Feb 2020 - July 2021 from various sources.</p>
      <p>Each dot represents an article, geometric distance between articles is calculated using their similarity.</p>
      <p><strong>Technologies used:</strong> python, <a target="_blank" rel="noreferrer" href="https://github.com/MaartenGr/BERTopic">BERTopic</a>, d3</p>
      <div className="CTA-Button">REQUEST FULL DEMO</div>
    </div>
  )
}

function ReinforcementLearning() {
  return (
    <div className="ReinforcementLearning">
      <Backgammon bgColor="#00000000" isDebug={false} />
      <div>
        <h2><span>Backgammon player trained with Reinforcement Learning</span></h2>
        <p>To play against the player, choose color and wait for your turn. Click the dice icon, select your move and confirm.</p>
        <p>The player is being continuously trained (new version is available every 30 minutes) by playing against itself and using modified TD-Lambda algorithm inspired by <a target="_blank" rel="noreferrer" href="https://www.bkgm.com/articles/tesauro/tdl.html">Gerald Tesauro's TD-Gammon</a></p>
        <p><strong>Technologies used:</strong> python, pytorch, numpy, react, d3</p>
      </div>
    </div>
  )
}

function Simulation() {
  return (
    <div className="Simulation">
      <D3 App={Visualisation} />
      <div>
        <h2><span>Simulation of a particle system</span></h2>
        <p>Above is a visualisation of a particle system, where each particle is attracted to only particles larger in size.</p>
        <p><strong>Technologies used:</strong> d3, react</p>
      </div>
    </div>
  )
}

function App() {
  useWindowSize()

  const { pathname } = useLocation()
  const screen = useDeviceScreen()

  const isHome = pathname === '/'
  const isHomeClass = isHome ? 'Is-Home' : ''

  const menu = (isHome && screen === 'mobile-portrait') || screen === 'desktop' || screen === 'mobile-landscape' ? <Menu /> : <Back />

  const router = isHome ? <></> : (
    <Switch>
      <Route path="/reinforcement-learning">
        <ReinforcementLearning />
      </Route>
      <Route path="/simulations-visualizations">
        <Simulation />
      </Route>
      <Route path="/natural-language-processing">
        <NLP />
      </Route>
      {/* <Route path="/">
        <div className="Home">
          Home
        </div>
      </Route> */}
    </Switch>
  )

  return (
    <div className={`App ${screen} ${isHomeClass}`}>
      {menu}
      {router}
    </div>
  );
}

export default function AppWithRouter() {
  return (
    <Router>
      <App />
    </Router>
  )
}
