/* eslint-disable */
import * as d3 from 'd3'
import * as R from 'ramda'
import randomColor from 'randomcolor'
import color from 'color'

import { nearest, findNearestBigger } from '@petlack/react-d3/geometry'
import { fullscreen, layer, measure } from '@petlack/react-d3/utils'

const createApp = (group) => {
  console.log('[ColorClusters] UPDATE')
  const svg = group.select("svg")

  const { width, height } = measure(svg)

  const aStr = d => d.strength * 2
  const xStr = d => d.r * 0.001
  const distMax = 3000
  const distMin = 10
  const col = d => d.r + 2
  const colorStr = 0.055
  const collisionStr = 1.7
  const iter = 1
  const xTargetX = width/2
  const xTargetY = height/2
  const alphaDecay = 0.1
  const alphaTarget = 0.2
  const count = 100
  const randomRadius = () => Math.max(d3.randomNormal(Math.random() * 15, 2)(), 2)

  const colorScale = d3.scaleLinear().domain([0, 255])
  const colorScaleX = colorScale.range([0, width])
  const colorScaleY = colorScale.range([0, height])
  // const colorInterpolateFn = (pad, dist, alpha) => Math.pow(2, (dist - pad) / dist * alpha) - 1
  // const colorInterpolateFn = dist => Math.sin(Math.PI * dist)
  const colorInterpolateFn = (pad, dist, alpha) => (dist - pad) / dist * alpha
  const colorInterpolate = (pad, dist, alpha) => colorInterpolateFn(pad, dist, alpha) * colorStr

  const colorTargetX = (d) => colorScaleX(d.color.green())
  const colorTargetY = (d) => colorScaleY(d.color.red())

  let nodes = []

  const xForce = d3.forceX().strength(xStr).x(xTargetX)
  const yForce = d3.forceY().strength(xStr).y(xTargetY)

  const attractForce = d3.forceManyBody().strength(aStr).distanceMax(distMax).distanceMin(distMin)
  const collisionForce = d3.forceCollide(col).strength(collisionStr).iterations(iter)

  const randomBump = (maxX, maxY) => (alpha) => {
    // const d = nodes[Math.floor(Math.random() * nodes.length)]
    nodes.forEach(d => {
      d.x += Math.random() * maxX - Math.floor(maxX/2)
      d.y += Math.random() * maxY - Math.floor(maxY/2)
    })
  }

  const sort = targetDist => interpolate => alpha =>
    nodes.forEach(d => {
      const dist = targetDist(d)
      const newpos = interpolate(d, dist, alpha)
      d.x -= newpos.dx
      d.y -= newpos.dy
    })

  const colorSort1 = colorInterpolate => sort(
      (d) => {
        const dx = d.x - colorTargetX(d)
        const dy = d.y - colorTargetY(d)
        return {
          dx,
          dy,
          dist: Math.sqrt(dx*dx + dy*dy),
        }
      }
    )(
      (d, dst, alpha) => {
        const { dx, dy } = dst
        let dist = dst.dist
        const pad = d.r * 2 + 2
        if (dist !== pad) {
          dist = colorInterpolate(pad, dist, alpha)
          return {
            dx: dx * dist,
            dy: dy * dist,
          }
        }
        return {
          dx: 0,
          dy: 0
        }
      }
    )

  // const colorSort = (interpolate) => (alpha) => {
  //   nodes.forEach(d => {
  //     let dx = d.x - colorTargetX(d)
  //     let dy = d.y - colorTargetY(d)
  //     let dist = Math.sqrt(dx*dx + dy*dy)
  //     const pad = d.r * 2 + 2
  //     if (dist !== pad) {
  //       dist = colorInterpolate(pad, dist, alpha)
  //       dx *= dist
  //       dy *= dist
  //       d.x -= dx
  //       d.y -= dy
  //     }
  //   })
  // }

  const bigSort = (colorInterpolate) => sort(
    (d) => {
      const target = R.reduce(R.maxBy(R.prop('r')), d, nodes)
      const {x: tx, y: ty} = target
      const dx = d.x - tx
      const dy = d.y - ty
      return {
        dx,
        dy,
        target,
        dist: Math.sqrt(dx*dx + dy*dy),
      }
    }
  )(
    (d, dst, alpha) => {
      const { dx, dy } = dst
      let dist = dst.dist
      const pad = d.r + dst.target.r + 2
      if (dist !== pad) {
        dist = colorInterpolate(pad, dist, alpha)
        return {
          dx: dx * dist,
          dy: dy * dist,
        }
      }
      return {
        dx: 0,
        dy: 0
      }
    }
  )

  let tree = d3.quadtree()

  const nearestBiggerForce = (alpha) => sort(
    (d) => {
      const target = findNearestBigger(colorTargetX, colorTargetY)(width, height)(nodes)(d)//tree.find(d.x, d.y)//nearest(d.x, d.y, {d: width+height, p: null}, tree)//
      const {x: tx, y: ty} = target
      const dx = d.x - tx
      const dy = d.y - ty
      return {
        dx,
        dy,
        target,
        dist: Math.sqrt(dx*dx + dy*dy),
      }
    }
  )(
    (d, dst, alpha) => {
      const { dx, dy } = dst
      let dist = dst.dist
      const pad = d.r + dst.target.r + 2
      if (dist !== pad) {
        dist = colorInterpolate(pad, dist, alpha)
        return {
          dx: dx * dist,
          dy: dy * dist,
        }
      }
      return {
        dx: 0,
        dy: 0
      }
    }
  )

  const colorSort = nearestBiggerForce
  // const colorSort = bigSort
  // const colorSort = colorSort1

  const simulation = d3.forceSimulation(nodes)
    .alphaDecay(alphaDecay)
    .alphaTarget(alphaTarget)
    // .force("xForce", xForce)
    // .force("yForce", yForce)
    .force("collisionForce", collisionForce)
    .force("colorSort", colorSort(colorInterpolate))
    // .force("randomBump", randomBump(4, 4))
    // .force("attractForce", attractForce)
    .on("tick", ticked)

  let node = svg.selectAll("circle")

  const restart = () => {
    node = node.data(nodes, d => d.id)
    node.exit().remove()
    const newNode = node.enter().append("circle")
    newNode.attr("r", 0).transition().duration(250).attr("r", d => d.r)
    node = newNode
    .merge(node)
    .attr("cx", d => d.x).attr("cy", d => d.y)
    .attr("fill", d => d.color.toString()).attr("opacity", 0.5)
    .call(d3.drag()
      .on("start", dragstarted)
      .on("drag", dragged)
      .on("end", dragended))
    simulation.nodes(nodes)
    // simulation.alpha(1)
    .alphaTarget(0.2)
    simulation.restart()
  }

  function dragstarted(d) {
     simulation.restart();
    //  simulation.alpha(1.0);
     d.fx = d.x;
     d.fy = d.y;
  }

  function dragged(event) {
    event.subject.fx = event.x;
    event.subject.fy = event.y;
  }

  function dragended(d) {
     d.fx = null;
     d.fy = null;
     simulation.alphaTarget(0.1);
  }

  const createQuadtree = (nodes) => d3.quadtree().x(R.prop('x')).y(R.prop('y')).addAll(nodes)

  const tickedX = d => Math.max(Math.min(d.x, width - d.r - 2), d.r + 2)
  const tickedY = d => Math.max(Math.min(d.y, height - d.r - 2), d.r + 2)

  function ticked() {
    // console.log('ticked')
    node
      .attr("cx", tickedX)
      .attr("cy", tickedY)
    // tree = createQuadtree(nodes)
  }

  const update = (data) => {
    nodes = data
    // tree = createQuadtree(nodes)
    restart()
  }

  // svg.on('click', function (coords) {
  //   // const coords = d3.mouse(this)
  //   nodes.push({x: coords[0], y: coords[1], r: 50, strength: 50, id: nodes.length, color: color(randomColor())})
  //   update(nodes)
  // })

  update( R.range(1, count).map(id => {
    const r = randomRadius()
    const rColor = color(randomColor())
    return {
      x: Math.random() * width,
      y: Math.random() * height,
      r,
      strength: Math.random() > 0.5 ? r : -r,
      id: id,
      color: rColor,
      red: rColor.red(),
      green: rColor.green(),
      blue: rColor.blue(),
    }
  }))
}

function ColorClusters(props) {
  console.log('WTFTFTFTF')
  console.log('[ColorClusters]', props)
  return createApp
}

ColorClusters.create = function(parent) {
  parent.append('svg').call(layer()).call(fullscreen(parent))
}

export default ColorClusters
