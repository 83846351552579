import React from 'react'

import {
  Link
} from 'react-router-dom'

import useDeviceScreen from './useDeviceScreen'

import Github from './media/Github'
import LinkedIn from './media/LinkedIn'
import MailTo from './media/MailTo'

import './Menu.css'

function Menu() {
  const screen = useDeviceScreen()

  return (
    <div className={`Menu ${screen}`}>
      <h1>datamean technologies</h1>

      <h2>About</h2>
      <div className="About">
        <p>
        datamean technologies ltd. is a software company specializing in software development &amp; research
        with main focus on tailor-made applications of reinforcement learning, natural language processing and data visualisation.
        </p>
      </div>

      <h2>Case Studies</h2>
      <div className="Case AI">  
        <Link to="/reinforcement-learning"><div>Reinforcement Learning</div></Link>
      </div>
      <div className="Case NLP">  
        <Link to="/natural-language-processing"><div>Natural Language Processing</div></Link>
      </div>
      <div className="Case Simulations">  
        <Link to="/simulations-visualizations"><div>Simulations &amp; Visualizations</div></Link>
      </div>
      
      <h2>Contact</h2>
      <div className="Contact">
        <a href="https://github.com/petlack"><Github /></a>
        <a href="https://www.linkedin.com/in/peter-laca-b3546353/"><LinkedIn /></a>
        <a href="mailto:hello@datamean.technology"><MailTo /></a>
      </div>
    </div>
  )
}

export default Menu
